<template>
    <div class="d-flex flex-column flex-grow-1 overflow-hidden position-relative" :class="modalTarget">
        
        <OTabs :tabsClass="'bim-plugin-tablist border-bottom'" ref="tabsRef" :id="id + '_tabs'" tabContentClass="overflow-auto">
            <template #afterNav v-if="$slots.headerContent" class="flex-grow-1 d-flex">
                <slot name="headerContent">
                </slot>
            </template>

            <OTab active :id="id">
                <template #title>
                    <div class="py-2 bim-plugin-tablist-tab" :class="activeClass">
                        <h5 class="bim-plugin-tablist-tab-title">{{ $t(headerTitle)}}</h5>
                    </div>
                </template>
                <div :class="$attrs.class" class="plugin-container position-relative" ref="body">
                    <slot></slot>
                </div>
            </OTab>
            <template v-if="tabs?.length">
                <OTab v-for="(tab, idx) of tabs" :id="tab.id"  :key="tab.index">
                    <template #title>
                        <div class="py-2 bim-plugin-tablist-tab" :class="activeClass">
                            <h5 class="bim-plugin-tablist-tab-title">
                                <span>{{ $t(tab.title)}}</span>
                                <div class="bim-plugin-tablist-tab-close" v-if="tab.removable" :title="$t('Close')" @click.stop="removeTab(tab.id)">
                                    <span>&#x00d7;</span>
                                </div>
                            </h5>
                        </div>
                    </template>
                    <div :class="$attrs.class" class="plugin-container position-relative" >
                        <component :is="typeof tab.component === 'string' ? 'div' : tab.component"></component>
                    </div>
                </OTab>
            </template>
        </OTabs>

    </div>
</template>

<script>
    let counter = 0;
</script>

<script setup>
    import { ref, defineExpose, computed, onBeforeUnmount, provide } from 'vue';

    defineOptions({ inheritAttrs: false });

    const props = defineProps({
        headerTitle: String,
        id: {
            type: String,
            required: false,
        }
    });

    const modalTarget = 'bim-plugin';

    const tabs = ref([]);
    const tabsRef = ref(null);

    const id = computed(() => props.id || `bim_plugin_${counter++}`);
    const activeClass = computed(() => tabs.value?.length ? 'bim-plugin-tablist-tab-active' : '');

    function addTabs(options) {
        console.log(tabs);
        if (typeof options?.[Symbol.iterator] === 'function') {
            options.forEach(o => {
                o.index = tabs.value.length;
                tabs.value.push(o);
            });
        } else {
            options.index = tabs.value.length;
            tabs.value.push(options);
        }
    }

    function removeTab(id) {
        const index = tabs.value.findIndex(t => t.id === id);
        if (index === -1) { return }

        const isActiveTabDeleted = tabs.value[index]?.id === tabsRef.value.getActiveTab();

        tabs.value.splice(index, 1);
        tabs.value.forEach((t, idx) => t.index === idx);

        if (isActiveTabDeleted) {
            tabsRef.value.setActiveTab(index === 0 ? id.value : tabs.value[index - 1]?.id);
        }
    }

    onBeforeUnmount(() => {
        tabs.value = [];
        console.log('onBeforeUnmount', props.headerTitle, tabs.value.length);
    })

    defineExpose({ addTabs, removeTab });

    provide('bimPluginModalTarget', '.' + modalTarget);
</script>

<style scoped>

    .plugin-container {
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
        /* row-gap: 1rem; */
        flex-grow: 1;
        overflow: auto;
    }

</style>

<style>
    .bim-plugin-tablist {
        --bim-plugin-tablist-padding-y: 0.5rem;
        --bim-plugin-tablist-padding-x: 1rem;
        --bim-plugin-tablist-active-color: var(--bs-link-hover-color);
        --bim-plugin-tablist-border-width: 1px;
        --bim-plugin-tablist-divider-width: 1px;

        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        color: var(--bs-dark-text-emphasis);
    }

    .bim-plugin-tablist-tab {
        position: relative;
        overflow: hidden;
    }

    .bim-plugin-tablist-tab-title {
        margin: 0;
        margin-right: var(--bim-plugin-tablist-divider-width);
        padding: var(--bim-plugin-tablist-padding-y) var(--bim-plugin-tablist-padding-x);
        display: flex;
        gap: 0.25rem;
        position: relative;
        white-space: nowrap;
        transition: border-color 0.3s, color 0.3s;
    }

    .bim-plugin-tablist-tab-active {
        color: var(--bs-gray-600);
    }

    .bim-plugin-tablist-tab-active::after {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--bim-plugin-tablist-active-color);
        height: 4px;
        inset: auto calc(var(--bim-plugin-tablist-padding-x) + var(--bim-plugin-tablist-divider-width)) 0 var(--bim-plugin-tablist-padding-x);
        border-top-left-radius: var(--bs-border-radius);
        border-top-right-radius: var(--bs-border-radius);
        translate: 0 100%;
        transition: translate 0.15s;
    }

    .bim-plugin-tablist-tab-close {
        position: relative;
        isolation: isolate;
    }

    .bim-plugin-tablist-tab-close::after {
        content: '';
        position: absolute;
        display: block;
        width: 120%;
        aspect-ratio: 1 / 1;
        background-color: transparent;
        border-radius: 50%;
        top: 58%;
        left: 50%;
        translate: -50% -50%;
        z-index: -1;
    }

    .bim-plugin-tablist-tab-close:hover::after {
        background-color: color-mix(in srgb, white 80%, var(--bs-danger));
    }
    .bim-plugin-tablist-tab-close:hover {
        color: var(--bs-danger);
    }

    .bim-plugin-tablist .active .bim-plugin-tablist-tab-active {
        color: var(--bim-plugin-tablist-active-color);
    }
    .bim-plugin-tablist .active .bim-plugin-tablist-tab-active::after {
        translate: 0 0;
    }
    .bim-plugin-tablist .nav-link:not(.active) .bim-plugin-tablist-tab-active:hover {
        color: unset;
    }
    .bim-plugin-tablist .nav-item:not(:last-of-type) .bim-plugin-tablist-tab-title::after {
        content: '';
        display: block;
        position: absolute;
        width: var(--bim-plugin-tablist-divider-width);
        background-color: var(--bs-gray-300);
        height: 80%;
        right: 0;
        top: 50%;
        translate: 100% -50%;
    }
</style>